import { useAuth } from "contexts/Auth/AuthContext";
import { Crop, PixelCrop } from "react-image-crop";
import canvasSize from "canvas-size";

const TO_RADIANS = Math.PI / 180;

declare var jivo_api: any;

export const IsValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return (
    !!urlPattern.test(urlString) &&
    (urlString.indexOf("http://") !== -1 ||
      urlString.indexOf("https://") !== -1)
  );
};

export const ValidateFileImage = (fileExtension: string) => {
  let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
  let isValidFile = false;
  for (let index in allowedExtension) {
    if (fileExtension === allowedExtension[index]) {
      isValidFile = true;
      break;
    }
  }
  return isValidFile;
};

export const convert_vi_to_en = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  str = str.replace(/  +/g, " ");
  return str;
};

export const array_move = (
  arr: any[],
  old_index: number,
  new_index: number
) => {
  if (new_index >= arr?.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr?.splice(old_index, 1)[0]);
  return arr;
};

export const openChat = () => {
  const params = { start: "chat" };
  jivo_api.open(params);
};

export const IsMobileScreen = () => {
  return window?.innerWidth < 768;
};

export const FormatCurrency = (
  input: any,
  currency: string | undefined,
  hideSymbol?: boolean | undefined
) => {
  const text = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency ?? "USD",
  }).format(input);
  if (hideSymbol) {
    return text.substring(1);
  }
  return text;
};

export const isActiveState = (state: string) => {
  const activeStates = ["active", "in_trial"];
  return activeStates.find((c) => c === state);
};

export const getSubscription = () => {
  const currentDateTime = new Date().toISOString();
  let subscription = localStorage.getItem("subscription");
  if (subscription === null) {
    return { subscriptionState: "no_subscription" };
  } else {
    try {
      const subscriptionData = JSON.parse(subscription);
      const auth = useAuth();
      const { status, cardStatus, expiredAt } = subscriptionData;
      let subscriptionState = "";
      if (
        auth.user?.isAdmin ||
        auth.user?.isPartner ||
        (status === "in_trial" && cardStatus === "valid") ||
        status === "active" ||
        status === "non_renewing"
      ) {
        subscriptionState = "active";
      } else if (status === "in_trial" && cardStatus !== "valid") {
        subscriptionState = "in_trial";
      } else if (expiredAt && currentDateTime < expiredAt) {
        subscriptionState = status === "cancelled" ? "in_trial" : "active";
      } else {
        subscriptionState = "expired";
      }
      return { ...subscriptionData, subscriptionState };
    } catch (error) {
      return { subscriptionState: "no_subscription" };
    }
  }
};
export const formatTrialEndDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
  } as Intl.DateTimeFormatOptions;
  const formattedDate = date.toLocaleDateString("en-US", options);
  const day = date.getDate();
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";
  return formattedDate.replace(/\d+/, day + suffix);
};

export const getDateFormat = (date: any) => {
  var dateTime = new Date(date);
  let hours = dateTime.getHours();
  let minutes = dateTime.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  let day = dateTime.getDate();
  let month = dateTime.toLocaleString("default", { month: "long" });
  let year = dateTime.getFullYear();
  let formattedHours = hours < 10 ? "0" + hours : hours;
  let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  let formattedDateTime =
    formattedHours +
    ":" +
    formattedMinutes +
    " " +
    ampm +
    " " +
    day +
    " " +
    month +
    " " +
    year;
  return formattedDateTime;
};

export const getTimeZone = () => {
  var currentDate = new Date();
  var timeZoneOffset = currentDate.getTimezoneOffset();
  var timeZoneOffsetHours = Math.abs(timeZoneOffset / 60);
  var isAheadOfGMT = timeZoneOffset < 0;
  return "GMT " + (isAheadOfGMT ? "+" : "-") + timeZoneOffsetHours;
};

export const isUrlStartWithSpecialCharacter = (url: string) => {
  if (!url) {
    return false;
  }

  const regex = new RegExp("^[A-Za-z0-9]+");
  const startWithValidChar = regex.test(url);
  return !startWithValidChar;
};

export const trimEnd = (str: string, character: string) => {
  if (!str) return str;
  while (str.endsWith(character)) {
    str = str.slice(0, -1);
  }
  return str;
};

export const getCanvasMaxSize = async () => {
  let value = localStorage.getItem("canvasSize");
  if (!value) {
    const results: any = await canvasSize.maxArea({
      max: 18000,
      min: 1,
      step: 100
    });
    
    value = JSON.stringify(results);
    localStorage.setItem("canvasSize", JSON.stringify(results));
  }

  const canvasMaxValues = JSON.parse(value);
  return canvasMaxValues;
};

export const getCanvasImageScaleByDevice = async (imageWidth: number, imageHeight: number) => {
  const valueCompare = imageWidth > imageHeight ? imageWidth : imageHeight;
  const canvasLimit = await getCanvasMaxSize();

  // Canvas width and height are always equal
  if (!canvasLimit || valueCompare <= canvasLimit.width) {
    return 1;
  }

  return canvasLimit / canvasLimit.width;
};

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}
